import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useAuthenticateMutation } from 'authentication/mutations/authenticate';
import { registerRoute, requestResetRoute } from 'authentication/routes';
import { KeyTriggeredButton } from 'common/components/KeyTriggerButton';
import { Link } from 'common/components/Link';
import { useGraphqlForm } from 'common/hooks/useGraphqlForm';
import { AuthMethod } from 'common/types/graphql-types';
import { useCallback, useEffect } from 'react';
import { Key } from 'ts-key-enum';
import { useMeLazyQuery } from 'user/queries/me';
import { useRouter } from 'next/dist/client/router';

type Inputs = {
  username: string;
  password: string;
};

const defaultValues: Inputs = {
  username: '',
  password: ''
};

export const LoginForm = () => {
  const { query } = useRouter();
  const [authenticate, { data, error, loading }] = useAuthenticateMutation();
  const [meQuery] = useMeLazyQuery();
  const { register, handleSubmit, errors } = useGraphqlForm<Inputs>(error, {
    defaultValues
  });

  const onSubmit = useCallback(
    async ({ username, password }: Inputs) => {
      if (loading) {
        return;
      }
      try {
        await authenticate({
          variables: {
            identifier: username,
            secret: password,
            authMethod: AuthMethod.Password
          }
        });
      } catch {}
    },
    [loading]
  );

  useEffect(() => {
    if (data?.authenticate) {
      meQuery();
    }
  }, [data]);

  return (
    <Grid container={true} justifyContent={'center'} spacing={3}>
      <Grid item={true} xs={12} sm={7}>
        <TextField
          fullWidth={true}
          label={'Username'}
          {...register('username', { required: 'Username is required.' })}
          helperText={errors?.username?.message}
          error={!!errors?.password || !!errors?.username}
        />
      </Grid>
      <Grid item={true} xs={12} sm={7}>
        <TextField
          fullWidth={true}
          label={'Password'}
          type={'password'}
          {...register('password', { required: 'Password is required.' })}
          helperText={errors?.password?.message}
          error={!!errors.password}
        />
      </Grid>
      <Grid item={true} xs={12} sm={7}>
        <Box width={'100%'} marginTop={4}>
          <KeyTriggeredButton
            fullWidth={true}
            name={'submit'}
            variant={'contained'}
            color={'primary'}
            keyTrigger={Key.Enter}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <CircularProgress /> : 'Login'}
          </KeyTriggeredButton>
        </Box>
      </Grid>
      <Grid item={true} xs={12}>
        <Box width={'100%'} textAlign={'center'}>
          <Typography variant={'caption'}>
            <Link href={requestResetRoute()} id={'forgotPassword'}>
              Forgot Password
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item={true} xs={12}>
        <Box width={'100%'} textAlign={'center'}>
          <Typography variant={'caption'}>
            Don't have an account yet? &nbsp;
            <Link href={registerRoute(query?.r as string)} id={'registerLink'}>
              Register now
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
