import { LoginForm } from 'authentication/components/LoginForm';
import { AuthenticationFormsLayout } from 'authentication/components/AuthenticationFormsLayout';
import { getApolloClient, NextApolloPageContext } from 'common/hooks/useApollo';
import { GetServerSidePropsContext } from 'next';

const LoginPage = () => {
  return <LoginForm />;
};

LoginPage.layout = AuthenticationFormsLayout;
export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const apolloClient = getApolloClient(undefined, ctx as NextApolloPageContext);
  return {
    props: {
      initialApolloState: apolloClient.cache.extract()
    }
  };
}

export default LoginPage;
